import Vue from 'vue'
import Vuex from 'vuex'
import i18n from '@/plugins/vue-i18n'
import { setCurrentLanguage } from '@/utils'
// modules
import auth from './modules/auth'
import common from './modules/common'
import modals from './modules/modals'
import user from './modules/user'
import mailing from './modules/mailing'
import lot from './modules/lot'

Vue.use(Vuex)

export default new Vuex.Store({
  actions: {
    setLang(_context, payload) {
      i18n.locale = payload
      setCurrentLanguage(payload)
    }
  },
  modules: {
    auth,
    common,
    modals,
    user,
    mailing,
    lot
  }
})
