import axios from 'axios'
import { baseURL } from '@/constants/config'
import { getToken } from '@/utils'

export const axiosWithBaseUrl = axios.create({
  baseURL
})

export const axiosWithToken = axios.create({
  baseURL
})

axiosWithToken.interceptors.request.use(
  (config) => {
    const token = getToken()

    if (token) {
      config.headers = {
        Authorization: `Token ${token}`,
        Accept: 'application/json',
        'Content-Type': 'application/json'
      }
    }
    return config
  },
  (error) => {
    Promise.reject(error)
  }
)
