import Vue from 'vue'
import VueRouter from 'vue-router'
import AuthGuard from './auth.guard'
Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: () => import(/* webpackChunkName: "home" */ '@/views/index.vue')
  },
  {
    path: '/home',
    name: 'AuthorizedHome',
    component: () =>
      import(/* webpackChunkName: "userHome" */ '@/views/user/index.vue'),
    meta: { loginRequired: true }
  },
  {
    path: '/products',
    name: 'Products',
    component: () =>
      import(/* webpackChunkName: "products" */ '@/views/products/index.vue')
  },
  {
    path: '/products/filter',
    name: 'ProductsFilter',
    component: () =>
      import(
        /* webpackChunkName: "productsFilter" */ '@/views/products/filter.vue'
      ),
    meta: { loginRequired: true }
  },
  {
    path: '/products/:id',
    name: 'SingleProduct',
    component: () =>
      import(/* webpackChunkName: "product" */ '@/views/products/_product.vue')
  },
  {
    path: '/user',
    name: 'UserPage',
    component: () =>
      import(/* webpackChunkName: "user" */ '@/views/user/_user.vue'),
    meta: { loginRequired: true }
  },
  {
    path: '/auth',
    name: 'Auth',
    component: () =>
      import(/* webpackChunkName: "auth" */ '@/views/auth/index.vue'),
    redirect: '/auth/login',
    children: [
      {
        path: 'login',
        name: 'Login',
        component: () =>
          import(/* webpackChunkName: "authLogin" */ '@/views/auth/login.vue')
      },
      {
        path: 'register',
        name: 'Register',
        component: () =>
          import(
            /* webpackChunkName: "authRegister" */ '@/views/auth/register.vue'
          )
      },
      {
        path: 'phone-code-confirm',
        name: 'PhoneCodeConfirm',
        component: () =>
          import(
            /* webpackChunkName: "authPhoneCodeConfirm" */ '@/views/auth/phone-code-confirm.vue'
          )
      },
      {
        path: 'reset-password',
        name: 'ResetPassword',
        component: () =>
          import(
            /* webpackChunkName: "authResetPassword" */ '@/views/auth/reset-password.vue'
          )
      },
      {
        path: 'reset-password/:token',
        name: 'ResetPasswordToken',
        component: () =>
          import(
            /* webpackChunkName: "authResetPasswordToken" */ '@/views/auth/reset-password-token.vue'
          )
      }
    ]
  },
  {
    path: '/error',
    component: () =>
      import(/* webpackChunkName: "error" */ '@/views/error.vue'),
    meta: { layout: 'error' }
  },
  {
    path: '*',
    component: () =>
      import(/* webpackChunkName: "error" */ '@/views/error.vue'),
    meta: { layout: 'error' }
  }
]

const router = new VueRouter({
  mode: 'history',
  linkActiveClass: 'router-link-active',
  routes,
  scrollBehavior(to, _from, savedPosition) {
    if (to.hash) {
      return {
        selector: to.hash
      }
    }
    if (savedPosition) {
      return savedPosition
    } else {
      return { x: 0, y: 0 }
    }
  }
})

router.beforeEach(AuthGuard)

export default router
