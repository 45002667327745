import store from '@/store'
import { setCodeExpiration, getCodeExpiration } from '@/utils'

export default async (to, _from, next) => {
  const isUserLoggedIn = store.getters['auth/isUserLoggedIn']
  const user = store.getters['auth/currentUser']
  const codeExpiration = getCodeExpiration()

  if (to.path.includes('phone-code-confirm')) {
    if (!codeExpiration) {
      next('/auth/login')
    } else if (new Date(codeExpiration).getTime() < new Date().getTime()) {
      setCodeExpiration(null)
      next('/auth/login')
    } else {
      next()
    }
  } else {
    next()
  }

  if (Object.keys(user).length === 0 && isUserLoggedIn) {
    await store.dispatch('auth/me')
  }

  if (to.path.includes('auth') && isUserLoggedIn) {
    next('/')
  } else next()

  if (to.path === '/' && isUserLoggedIn) {
    next('/home')
  } else next()

  if (
    to.matched.length > 0 &&
    to.matched.some((record) => record.meta.loginRequired)
  ) {
    if (isUserLoggedIn) {
      next()
    } else {
      next('/auth/login')
    }
  } else {
    next()
  }
}
