<template>
  <input v-mask="config" type="text" :value="display" @input="onInput" />
</template>

<script>
import mask from './directive'
import tokens from './tokens'
import masker from './masker'

export default {
  name: 'TheMask',
  directives: { mask },
  props: {
    value: {
      type: [String, Number],
      default: ''
    },
    mask: {
      type: [String, Array],
      required: true
    },
    masked: {
      type: Boolean,
      default: false
    },
    tokens: {
      type: Object,
      default: () => tokens
    }
  },
  data() {
    return {
      lastValue: null,
      display: this.value
    }
  },
  computed: {
    config() {
      return {
        mask: this.mask,
        tokens: this.tokens,
        masked: this.masked
      }
    }
  },
  watch: {
    value(newValue) {
      if (newValue !== this.lastValue) {
        this.display = newValue
      }
    },
    masked() {
      this.refresh(this.display)
    }
  },
  methods: {
    onInput(e) {
      if (e.isTrusted) return
      this.refresh(e.target.value)
    },
    refresh(value) {
      this.display = value
      const refreshValue = masker(value, this.mask, this.masked, this.tokens)
      if (value !== this.lastValue) {
        this.lastValue = refreshValue
        this.$emit('input', refreshValue)
      }
    }
  }
}
</script>
