<template>
  <component :is="layout">
    <router-view />
  </component>
</template>

<script>
export default {
  name: 'App',
  computed: {
    layout() {
      const layout = this.$route.meta.layout || 'default'
      return () => import(`@/components/layouts/${layout}.vue`)
    }
  }
}
</script>
