import Vue from 'vue'
import VueI18n from 'vue-i18n'
import { extend } from 'vee-validate'
import {
  required,
  min,
  max,
  alpha,
  length,
  email,
  confirmed,
  regex,
  is_not
} from 'vee-validate/dist/rules'
import { defaultLocale, localeOptions } from '@/constants/config'
import { getCurrentLanguage, decimalRegex, webURLRegex } from '@/utils'

import ru from '@/locales/ru.json'
import uz from '@/locales/uz.json'

Vue.use(VueI18n)

const messages = { ru, uz }

const locale =
  getCurrentLanguage() &&
  localeOptions.filter((x) => x.id === getCurrentLanguage()).length > 0
    ? getCurrentLanguage()
    : defaultLocale

const i18n = new VueI18n({
  locale: locale,
  fallbackLocale: 'ru',
  messages
})

extend('required', {
  ...required,
  message: (field, values) => i18n.t('formErrors.fieldRequired', values)
})

extend('min', {
  ...min,
  message: (field, values) =>
    i18n.t(`formErrors.${field}Min${values.length}Char`, values)
})

extend('max', {
  ...max,
  message: (field, values) =>
    i18n.t(`formErrors.${field}Max${values.length}Char`, values)
})

extend('alpha', {
  ...alpha,
  message: (_, values) => i18n.t('formErrors.fieldMustBeAlpha', values)
})

extend('length', {
  ...length,
  message: (field, values) =>
    i18n.t(`formErrors.${field}${values.length}Char`, values)
})

extend('email', {
  ...email,
  message: (_, values) => i18n.t('formErrors.mustBeValidEmail', values)
})

extend('confirmed', {
  ...confirmed,
  message: (field, values) => i18n.t(`formErrors.${field}NotMatch`, values)
})

extend('regex', {
  ...regex,
  message: (_, values) => {
    switch (String(values.regex)) {
      case String(/^(?=.*?\d)(?=.*?[a-zA-Z])[a-zA-Z\d]+$/):
        return i18n.t(`formErrors.fieldMustBeBothAlphaNum`, values)

      case String(decimalRegex):
        return i18n.t(`formErrors.mustBeNumber`, values)

      case String(webURLRegex):
        return i18n.t(`formErrors.fieldNotWebsite`, values)

      default:
        return `Must match the following regex: ${values.regex}`
    }
  }
})

extend('is_not', {
  ...is_not,
  message: (_, values) => {
    return i18n.t(`formErrors.${values.other}NotSame`, values)
  }
})

export default i18n
