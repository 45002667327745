import cookies from 'vue-cookies'
import { localeOptions, defaultLocale } from '@/constants/config'

/* Regex */
export const decimalRegex = /^\d*\.?\d*$/

export const webURLRegex =
  /(https?:\/\/)?(www\.)[-a-zA-Z\d@:%._+~#=]{2,256}\.[a-z]{2,4}\b([-a-zA-Z\d@:%_+.~#?&/=]*)|(https?:\/\/)?(www\.)?(?!ww)[-a-zA-Z\d@:%._+~#=]{2,256}\.[a-z]{2,4}\b([-a-zA-Z\d@:%_+.~#?&/=]*)/

/* Helper functions */
export const capitalize = (string) => {
  if (typeof string !== 'string') return ''
  return string.charAt(0).toUpperCase() + string.slice(1)
}

export const isObject = (val) => {
  return val != null && typeof val === 'object' && Array.isArray(val) === false
}

export const wait = (time) => {
  return new Promise((resolve) => setTimeout(resolve, time))
}

export const formatPhoneNumber = (phoneNumberString) => {
  return phoneNumberString.replaceAll(/[^0-9]/gi, '')
}

export const beautifyPhoneNumber = (string) => {
  const cleaned = formatPhoneNumber(string)
  const match = cleaned.match(/^(\d{3})(\d{2})(\d{3})(\d{2})(\d{2})$/)
  return match
    ? `+${match[1]} (${match[2]}) ${match[3]}-${match[4]}-${match[5]}`
    : ''
}

export const getDateWithFormat = (date) => {
  const selectedDate = new Date(date)
  let dd = selectedDate.getDate()
  let mm = selectedDate.getMonth() + 1

  const yyyy = selectedDate.getFullYear()
  if (dd < 10) {
    dd = '0' + dd
  }
  if (mm < 10) {
    mm = '0' + mm
  }

  return `${dd}.${mm}.${yyyy}`
}

export const formatDateForInput = (date) => {
  const selectedDate = new Date(date)
  let dd = selectedDate.getDate()
  let mm = selectedDate.getMonth() + 1

  const yyyy = selectedDate.getFullYear()
  if (dd < 10) {
    dd = '0' + dd
  }
  if (mm < 10) {
    mm = '0' + mm
  }

  return `${yyyy}-${mm}-${dd}`
}

export const getDateAndTimeWithFormat = (date) => {
  const selectedDate = new Date(date)
  let dd = selectedDate.getDate()
  let mm = selectedDate.getMonth() + 1
  let hr = selectedDate.getHours()
  let min = selectedDate.getMinutes()

  const yyyy = selectedDate.getFullYear()
  if (dd < 10) {
    dd = '0' + dd
  }
  if (mm < 10) {
    mm = '0' + mm
  }
  if (hr < 10) {
    hr = '0' + hr
  }
  if (min < 10) {
    min = '0' + min
  }

  return `${dd}.${mm}.${yyyy} ${hr}:${min}`
}

export const addMinutes = (numOfMinutes, date = new Date()) => {
  date.setMinutes(date.getMinutes() + numOfMinutes)
  return date
}

export const toPercent = (num) => {
  let initialNum = Math.abs(num)
  return Math.round(initialNum * 100)
}

export const getCurrentLanguage = () => {
  let locale = defaultLocale
  try {
    if (
      localStorage.getItem('currentLanguage') &&
      localeOptions.filter(
        (x) => x.id === localStorage.getItem('currentLanguage')
      ).length > 0
    ) {
      locale = localStorage.getItem('currentLanguage')
    }
  } catch (err) {
    console.error(err)
    locale = defaultLocale
  }
  return locale
}

export const setCurrentLanguage = (lang) => {
  try {
    localStorage.setItem('currentLanguage', lang)
  } catch (err) {
    console.error(err)
  }
}

export const getToken = () => {
  let token
  try {
    token = cookies.get('token') !== null ? cookies.get('token') : null
  } catch (err) {
    console.error(err)
    token = null
  }
  return token
}

export const setToken = (token) => {
  try {
    if (token) {
      cookies.set('token', token, 60 * 60 * 24 * 7, '/')
    } else {
      cookies.remove('token')
    }
  } catch (err) {
    console.error(err)
  }
}

export const getCodeExpiration = () => {
  let codeExpiration
  try {
    codeExpiration =
      cookies.get('codeExpiration') !== null
        ? cookies.get('codeExpiration')
        : null
  } catch (err) {
    console.error(err)
    codeExpiration = null
  }
  return codeExpiration
}

export const setCodeExpiration = (codeExpiration) => {
  try {
    if (codeExpiration) {
      cookies.set('codeExpiration', codeExpiration, 60 * 60 * 24 * 7, '/')
    } else {
      cookies.remove('codeExpiration')
    }
  } catch (err) {
    console.error(err)
  }
}

export const ObjectFilterOut = (object, filterKeys) => {
  const langFilterKeys = []
  for (const key of filterKeys) {
    langFilterKeys.push(key.concat('_', 'ru'))
    langFilterKeys.push(key.concat('_', 'uz'))
  }
  const asArray = Object.entries(object)
  const filtered = asArray.filter(
    // eslint-disable-next-line no-unused-vars
    ([key, _value]) => !langFilterKeys.includes(key)
  )
  return Object.fromEntries(filtered)
}

export const handleArrayDataInternalization = (
  data,
  langKeys,
  currentLang,
  newKeyValue = null
) => {
  if (data) {
    switch (currentLang) {
      case 'ru':
        return data.map((i) => {
          const langData = {}

          for (const key of langKeys) {
            const langKey = key.concat('_', 'ru')
            if (i[langKey]) {
              langData[key] = i[langKey]
            }
          }

          if (newKeyValue) {
            const newKey = newKeyValue[0]
            const newValues = newKeyValue[1].includes('-')
              ? newKeyValue[1]
                  .split('-')
                  .map((newValue) =>
                    langKeys.includes(newValue)
                      ? i[newValue + '_ru']
                      : i[newValue]
                  )
                  .join('-')
              : langKeys.includes(newKeyValue[1])
              ? i[newKeyValue[1] + '_ru']
              : i[newKeyValue[1]]

            return {
              ...ObjectFilterOut(i, langKeys),
              ...langData,
              [newKey]: newKeyValue[2] + newValues
            }
          }

          return {
            ...ObjectFilterOut(i, langKeys),
            ...langData
          }
        })

      case 'uz':
        return data.map((i) => {
          const langData = {}

          for (const key of langKeys) {
            const langKey = key.concat('_', 'uz')
            if (i[langKey]) {
              langData[key] = i[langKey]
            }
          }

          if (newKeyValue) {
            const newKey = newKeyValue[0]
            const newValues = newKeyValue[1].includes('-')
              ? newKeyValue[1]
                  .split('-')
                  .map((newValue) =>
                    langKeys.includes(newValue)
                      ? i[newValue + '_uz']
                      : i[newValue]
                  )
                  .join('-')
              : langKeys.includes(newKeyValue[1])
              ? i[newKeyValue[1] + '_uz']
              : i[newKeyValue[1]]

            return {
              ...ObjectFilterOut(i, langKeys),
              ...langData,
              [newKey]: newKeyValue[2] + newValues
            }
          }

          return {
            ...ObjectFilterOut(i, langKeys),
            ...langData
          }
        })

      default:
        return []
    }
  }
  return []
}

export const handleObjectInternalization = (data, langKeys, currentLang) => {
  const langData = {}
  switch (currentLang) {
    case 'ru':
      for (const key of langKeys) {
        const langKey = key.concat('_', 'ru')
        if (data[langKey]) {
          langData[key] = data[langKey]
        }
      }

      return {
        ...ObjectFilterOut(data, langKeys),
        ...langData
      }

    case 'uz':
      for (const key of langKeys) {
        const langKey = key.concat('_', 'uz')
        if (data[langKey]) {
          langData[key] = data[langKey]
        }
      }

      return {
        ...ObjectFilterOut(data, langKeys),
        ...langData
      }

    default:
      return []
  }
}

export const formatPrice = (number, n, x, s, c) => {
  /**
   * Number.prototype.format(n, x, s, c)
   *
   * @param number number: number to format
   * @param integer     n: length of decimal
   * @param integer     x: length of whole part
   * @param mixed       s: sections delimiter
   * @param mixed       c: decimal delimiter
   */
  const re = '\\d(?=(\\d{' + (x || 3) + '})+' + (n > 0 ? '\\D' : '$') + ')'
  const num = number.toFixed(Math.max(0, ~~n))

  return (c ? num.replace('.', c) : num).replace(
    new RegExp(re, 'g'),
    '$&' + (s || ',')
  )
}

export const removeWhiteSpace = (text) => {
  const possibleWhiteSpaces = ['</p>\n<p>', '<p>&nbsp;</p>', '<p><br></p>']
  let res = text
  for (const space of possibleWhiteSpaces) {
    if (res.includes(space)) {
      const regex = new RegExp(space, 'g')
      res = res.replace(regex, '')
    }
  }
  return res
}

export const groupBy = function (xs, key) {
  return xs.reduce(function (rv, x) {
    ;(rv[x[key]] = rv[x[key]] || []).push(x)
    return rv
  }, {})
}

export const findObjectInArray = (arr = [], param, query) => {
  if ((Array.isArray(arr) && arr.length === 0) || !param || !query) return {}
  const obj = arr.find((el) => el[param] === query)
  return (isObject(obj) && obj) || {}
}
