export const baseURL = 'https://dxiu.uz'
//export const baseURL = 'http://localhost:8000/'


export const defaultLocale = 'ru'

export const localeOptions = [
  { id: 'ru', name: 'Русский' },
  { id: 'uz', name: 'O’zbek' }
]

export const currencyList = {
  1: { ru: 'UZS', uz: "So'm" },
  2: { ru: 'USD', uz: 'Dollar' },
  3: { ru: 'RUB', uz: 'Ruble' },
  4: { ru: 'EUR', uz: 'Euro' }
}
