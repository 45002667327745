import {
  setToken,
  getToken,
  isObject,
  addMinutes,
  setCodeExpiration
} from '@/utils'
import { axiosWithBaseUrl, axiosWithToken } from '@/api/axios'
import router from '@/router'

const state = () => ({
  currentUser: {},
  isUserLoggedIn: !!getToken(),
  processing: false
})

const getters = {
  currentUser: (state) => state.currentUser,
  isUserLoggedIn: (state) => state.isUserLoggedIn,
  processing: (state) => state.processing
}

const mutations = {
  SET_USER(state, payload) {
    state.currentUser = payload
    state.isUserLoggedIn = true
    state.processing = false
  },
  SET_LOGOUT(state) {
    state.currentUser = {}
    state.isUserLoggedIn = false
    state.processing = false
  },
  SET_PROCESSING(state, payload) {
    state.processing = payload
  }
}

const actions = {
  async register({ commit, dispatch }, { payload, notify }) {
    commit('SET_PROCESSING', true)
    try {
      await axiosWithBaseUrl.post('/account/register', payload)
      setCodeExpiration(addMinutes(2))
      router.push('/auth/phone-code-confirm')
    } catch (err) {
      window.scrollTo(0, 0)
      dispatch('common/setRequestError', { err, notify }, { root: true })
    } finally {
      commit('SET_PROCESSING', false)
    }
  },
  async send_email({ commit, dispatch }, { payload, notify }) {
    commit('SET_PROCESSING', true)
    try {
      await axiosWithBaseUrl.post('/api/try-free-form', payload)
      router.push('/auth/register')      
    } catch (err) {
      dispatch('common/setRequestError', { err, notify }, { root: true })
    } finally {
      commit('SET_PROCESSING', false)
    }
  },
  async login({ commit, dispatch }, { payload, notify }) {
    commit('SET_PROCESSING', true)
    try {
      const res = await axiosWithBaseUrl.post('/account/login', payload)
      const token = res.data.access
      dispatch('setTokenAndRedirectHome', token)
    } catch (err) {
      if (
        err &&
        isObject(err.response) &&
        isObject(err.response.data) &&
        Array.isArray(err.response.data.phone)
      ) {
        if (err.response.data.phone[0].includes('code')) {
          setCodeExpiration(addMinutes(2))
          router.push('/auth/phone-code-confirm')
          return
        }
      }
      dispatch('common/setRequestError', { err, notify }, { root: true })
    } finally {
      commit('SET_PROCESSING', false)
    }
  },
  async me({ commit, dispatch }) {
    try {
      const res = await axiosWithToken.get('/account/profile')
      commit('SET_USER', res.data)
    } catch (err) {
      dispatch('common/setRequestError', { err }, { root: true })
    }
  },
  async phoneCodeConfirm({ commit, dispatch }, { payload, notify }) {
    try {
      commit('SET_PROCESSING', true)
      const res = await axiosWithToken.post('/account/phone-code-confirm', {
        phone_code: payload
      })
      const token = res.data.access
      dispatch('setTokenAndRedirectHome', token)
    } catch (err) {
      await dispatch(
        'common/setRequestError',
        { err, notify, message: 'phone_code' },
        { root: true }
      )
      if (
        err &&
        isObject(err.response) &&
        isObject(err.response.data) &&
        err.response.status === 400
      ) {
        setCodeExpiration(null)
        router.push('/auth/login')
      }
    } finally {
      commit('SET_PROCESSING', false)
    }
  },
  async setTokenAndRedirectHome({ dispatch }, token) {
    setToken(token)
    setCodeExpiration(null)
    await dispatch('me')
    router.push('/home')
  },
  signOut({ commit }) {
    setToken(null)
    commit('SET_LOGOUT')
    router.push('/')
  }
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}
