import { axiosWithToken } from '@/api/axios'

const state = () => ({
  mailing: [],
  isLoading: false
})

const getters = {
  mailing: (state) => state.mailing,
  isLoading: (state) => state.isLoading
}

const mutations = {
  SET_MAILING(state, payload) {
    state.mailing = payload
  },
  SET_LOADING(state, payload) {
    state.isLoading = payload
  }
}

const actions = {
  async fetchUserMailing({ commit, dispatch }, loading = true) {
    if (loading) {
      commit('SET_LOADING', true)
    }
    try {
      const res = await axiosWithToken.get('/api/user/mailing')
      commit('SET_MAILING', res.data)
    } catch (err) {
      dispatch('common/setRequestError', { err }, { root: true })
    } finally {
      setTimeout(() => {
        if (loading) {
          commit('SET_LOADING', false)
        }
      }, 500)
    }
  },
  async createMailing({ commit, dispatch }, { payload, callback }) {
    commit('modals/SET_LOADING', true, { root: true })
    try {
      await axiosWithToken.post('/api/user/mailing', payload)
      callback()
      dispatch('fetchUserMailing', false)
    } catch (err) {
      dispatch('common/setRequestError', { err }, { root: true })
    } finally {
      commit('modals/SET_LOADING', false, { root: true })
    }
  },
  async deleteMailing({ commit, dispatch }, id) {
    commit('SET_LOADING', true)
    try {
      await axiosWithToken.delete(`/api/user/mailing/${id}`)
      await dispatch('fetchUserMailing', false)
    } catch (err) {
      dispatch('common/setRequestError', { err }, { root: true })
    } finally {
      commit('SET_LOADING', false)
    }
  }
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}
