import Vue from 'vue'
import {
  ValidationProvider,
  ValidationObserver,
  setInteractionMode
} from 'vee-validate'

setInteractionMode('eager')

Vue.component('ValidationObserver', ValidationObserver)
Vue.component('ValidationProvider', ValidationProvider)
