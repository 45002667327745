import { axiosWithToken } from '@/api/axios'
import router from '@/router'
import { wait } from '@/utils'

/**
 * @typedef {Object} PostFilter
 * @property {string} search_keywords
 * @property {string} exclude_keywords
 * @property {string} price_from
 * @property {string} price_to
 * @property {number} purchase_methods
 * @property {number} category
 * @property {number} region
 * @property {number[]} sources
 * @property {number[]} statuses
 * @property {string} started_at
 * @property {string} ended_at
 */

/**
 * @typedef {Object} UrlFilter
 * @property {string} search_keywords
 * @property {string} exclude_keywords
 * @property {string} min_price
 * @property {string} max_price
 * @property {number} purchase_method
 * @property {number} category
 * @property {number} region
 * @property {number[]} sources
 * @property {number[]} status
 * @property {string} start
 * @property {string} end
 * @property {number} filter
 */

const state = () => ({
  subscriptions: [],
  userFilters: [],
  singleFilter: {},
  isLoading: false
})

const getters = {
  subscriptions: (state) => state.subscriptions,
  userFilters: (state) => state.userFilters,
  singleFilter: (state) => state.singleFilter,
  isLoading: (state) => state.isLoading
}

const mutations = {
  SET_SUBSCRIPTIONS(state, payload) {
    state.subscriptions = payload
  },
  SET_USER_FILTERS(state, payload) {
    state.userFilters = payload
  },
  SET_SINGLE_FILTER(state, payload) {
    state.singleFilter = payload
  },
  SET_LOADING(state, payload) {
    state.isLoading = payload
  },
  CLEAR_FILTER_DATA(state) {
    state.userFilters = []
    state.singleFilter = {}
    state.isLoading = false
  }
}

const actions = {
  async updatePersonalData({ dispatch }, { payload, callback }) {
    try {
      await axiosWithToken.put('/account/profile', payload)
      await dispatch('auth/me', null, { root: true })
      callback()
    } catch (err) {
      dispatch('common/setRequestError', { err }, { root: true })
    }
  },
  async updatePassword({ dispatch }, { payload, callback, notify }) {
    try {
      await axiosWithToken.put('/account/change_password', {
        ...payload
      })
      callback()
    } catch (err) {
      dispatch('common/setRequestError', { err, notify }, { root: true })
    }
  },
  async fetchSubscription({ commit, dispatch }) {
    commit('SET_LOADING', true)
    await wait(500)
    try {
      const res = await axiosWithToken.get('/api/subscription')
      commit('SET_SUBSCRIPTIONS', res.data)
    } catch (err) {
      dispatch('common/setRequestError', { err }, { root: true })
    } finally {
      commit('SET_LOADING', false)
    }
  },

  /* reset password */
  async sendResetPasswordCode(
    { commit, dispatch },
    { payload, notify, callback }
  ) {
    commit('SET_LOADING', true)
    try {
      const res = await axiosWithToken.post(
        '/account/send-reset-password-code',
        { phone: payload }
      )
      callback(res.data.detail)
    } catch (err) {
      dispatch(
        'common/setRequestError',
        { err, notify, message: 'phone' },
        { root: true }
      )
    } finally {
      commit('SET_LOADING', false)
    }
  },
  async resetPasswordGetToken(
    { commit, dispatch },
    { payload, notify, callback }
  ) {
    commit('SET_LOADING', true)
    try {
      const res = await axiosWithToken.post(
        '/account/reset-password-get-token',
        { phone_code: payload }
      )
      const token = res.data.token
      router.push(`/auth/reset-password/${token}`)
      callback()
    } catch (err) {
      dispatch(
        'common/setRequestError',
        { err, notify, message: 'phone_code' },
        { root: true }
      )
    } finally {
      commit('SET_LOADING', false)
    }
  },
  async resetPassword({ commit, dispatch }, { payload, token, notify }) {
    commit('SET_LOADING', true)
    try {
      const res = await axiosWithToken.post(
        `/account/reset-password/${token}`,
        { ...payload }
      )
      const access = res.data.access
      dispatch('auth/setTokenAndRedirectHome', access, { root: true })
    } catch (err) {
      dispatch('common/setRequestError', { err, notify }, { root: true })
    } finally {
      commit('SET_LOADING', false)
    }
  },

  // filter CRUD
  async fetchUserFilters({ commit, dispatch }, loading = true) {
    if (loading) {
      commit('SET_LOADING', true)
    }
    try {
      const res = await axiosWithToken.get('/api/user/filter')
      commit('SET_USER_FILTERS', res.data)
    } catch (err) {
      dispatch('common/setRequestError', { err }, { root: true })
    } finally {
      setTimeout(() => {
        if (loading) {
          commit('SET_LOADING', false)
        }
      }, 500)
    }
  },
  async createFilter({ commit, dispatch }, { payload, callback }) {
    commit('modals/SET_LOADING', true, { root: true })
    try {
      await axiosWithToken.post('/api/user/filter', payload)
      callback()
      dispatch('fetchUserFilters')
    } catch (err) {
      dispatch('common/setRequestError', { err }, { root: true })
    } finally {
      commit('modals/SET_LOADING', false, { root: true })
    }
  },
  async fetchFilter({ commit, dispatch }, id) {
    commit('modals/SET_LOADING', true, { root: true })
    try {
      const res = await axiosWithToken.get(`/api/user/filter/${id}`)
      commit('SET_SINGLE_FILTER', res.data)
    } catch (err) {
      dispatch('common/setRequestError', { err }, { root: true })
    } finally {
      setTimeout(() => {
        commit('modals/SET_LOADING', false, { root: true })
      }, 500)
    }
  },
  async updateFilter({ commit, dispatch }, { payload, id, callback }) {
    commit('modals/SET_LOADING', true, { root: true })
    try {
      await axiosWithToken.put(`/api/user/filter/${id}`, payload)
      callback()
      dispatch('fetchUserFilters', false)
    } catch (err) {
      dispatch('common/setRequestError', { err }, { root: true })
    } finally {
      commit('modals/SET_LOADING', false, { root: true })
    }
  },
  async deleteFilter({ commit, dispatch }, id) {
    commit('SET_LOADING', true)
    try {
      await axiosWithToken.delete(`/api/user/filter/${id}`)
      await dispatch('fetchUserFilters', false)
    } catch (err) {
      dispatch('common/setRequestError', { err }, { root: true })
    } finally {
      commit('SET_LOADING', false)
    }
  }
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}
