'use strict'
import Vue from 'vue'

const safeHTML = (htmlString) => {
  const doc = document.implementation.createHTMLDocument('')
  const div = doc.createElement('div')

  div.innerHTML = htmlString

  for (
    let elements = div.querySelectorAll('*'), i = elements.length - 1;
    i >= 0;
    i--
  ) {
    const element = elements[i]
    const tagName = element.localName

    if (
      tagName === 'script' ||
      tagName === 'noscript' ||
      tagName === 'noembed' ||
      !(element.attributes instanceof NamedNodeMap)
    ) {
      try {
        element.parentNode?.removeChild(element)
      } catch (e) {
        element.outerHTML = ''
      }
      continue
    }

    if (!element.hasAttributes()) continue

    for (
      let attributes = element.attributes, j = attributes.length - 1;
      j >= 0;
      j--
    ) {
      const attribute = attributes[j]
      const attributeName = attribute.localName
      const attributeValue = attribute.value
        .replace(
          // eslint-disable-next-line no-control-regex
          /[\u0000-\u0020\u00A0\u1680\u180E\u2000-\u2029\u205F\u3000]/g,
          ''
        )
        .toLowerCase()
        .trim()

      if (attributeName.indexOf('on') === 0)
        element.removeAttribute(attributeName)
      else if (
        (attributeName === 'src' || attributeName === 'href') &&
        attributeValue.indexOf('javascript:') === 0
      )
        element.removeAttribute(attributeName)
      else if (
        !['audio', 'image', 'img', 'source', 'video'].includes(tagName) &&
        (attributeName === 'src' || attributeName === 'data') &&
        attributeValue.indexOf('data:') === 0
      )
        element.removeAttribute(attributeName)
    }
  }

  return div.innerHTML
}

const vueHTML = {
  install(Vue) {
    Vue.directive('html-safe', {
      inserted(el, binding) {
        el.innerHTML = safeHTML(binding.value)
      },

      update(el, binding) {
        if (binding.value !== binding.oldValue)
          el.innerHTML = safeHTML(binding.value)
      }
    })
  }
}

Vue.use(vueHTML)
