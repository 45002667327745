const state = () => ({
  currentId: null,
  modalOpen: false,
  isLoading: false
})

const getters = {
  currentId: (state) => state.currentId,
  modalOpen: (state) => state.modalOpen,
  isLoading: (state) => state.isLoading
}

const mutations = {
  SET_CURRENT_ID(state, payload) {
    state.currentId = payload
  },
  SET_MODAL_OPEN(state, payload) {
    state.modalOpen = payload
  },
  SET_LOADING(state, payload) {
    state.isLoading = payload
  }
}

const actions = {
  closeModal({ commit }) {
    document.documentElement.classList.remove('overflow-y-hidden')
    commit('SET_MODAL_OPEN', false)
    commit('SET_CURRENT_ID', null)
  },
  showModal({ commit }, id) {
    commit('SET_CURRENT_ID', id)
    commit('SET_MODAL_OPEN', true)
    document.documentElement.classList.add('overflow-y-hidden')
  }
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}
