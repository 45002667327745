import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import i18n from './plugins/vue-i18n'

import '@/scss/global.scss'
import '@/plugins/vue-select'
import '@/plugins/vee-validate'
import '@/plugins/vue-frag'
import '@/plugins/vue-html'
import '@/plugins/vue-cookies'
import '@/plugins/vue-notification'
import '@/plugins/vue-mask'
import '@/plugins/vue-countdown'

Vue.config.productionTip = false

new Vue({
  i18n,
  store,
  router,
  render: (h) => h(App)
}).$mount('#app')
