import { axiosWithBaseUrl, axiosWithToken } from '@/api/axios'

const state = () => ({
  lots: [],
  lotsData: {
    count: 0
  },
  bookmarkedLots: [],
  singleLot: {},
  isLoading: false
})

const getters = {
  lots: (state) => state.lots,
  lotsData: (state) => state.lotsData,
  bookmarkedLots: (state) => state.bookmarkedLots,
  singleLot: (state) => state.singleLot,
  isLoading: (state) => state.isLoading
}

const mutations = {
  SET_LOTS(state, payload) {
    state.lots = payload.results.sort((a, b) => b.id - a.id)
    state.lotsData.count = payload.count
  },
  SET_BOOKMARKED_LOTS(state, payload) {
    state.bookmarkedLots = payload
  },
  SET_SINGLE_LOT(state, payload) {
    state.singleLot = payload
  },
  SET_LOADING(state, payload) {
    state.isLoading = payload
  }
}

const actions = {
  async fetchFilteredLots({ commit, dispatch }, { perPage, offset, payload }) {
    commit('SET_LOADING', true)
    const searchParams = new URLSearchParams()
    for (const key in payload) {
      const value = payload[key]
      if (Array.isArray(value)) {
        for (let i = 0; i < value.length; i++) {
          searchParams.append(key, value[i])
        }
      } else {
        searchParams.append(key, value)
      }
    }

    try {
      const res = await axiosWithBaseUrl.get(
        `/api/lot-filter?${searchParams.toString()}`,
        {
          params: {
            limit: perPage,
            offset
          }
        }
      )
      commit('SET_LOTS', res.data)
    } catch (err) {
      dispatch('common/setRequestError', { err }, { root: true })
    } finally {
      setTimeout(() => {
        commit('SET_LOADING', false)
      }, 500)
    }
  },
  async fetchSingleLot({ commit, dispatch }, id) {
    commit('SET_LOADING', true)
    try {
      const res = await axiosWithBaseUrl.get(`/api/lot/${id}`)
      commit('SET_SINGLE_LOT', res.data)
    } catch (err) {
      dispatch('common/setRequestError', { err }, { root: true })
    } finally {
      setTimeout(() => {
        commit('SET_LOADING', false)
      }, 500)
    }
  },
  async bookmarkSingleLot({ dispatch }, payload) {
    try {
      await axiosWithToken.post('/api/user/favorite', {
        ...payload
      })
    } catch (err) {
      dispatch('common/setRequestError', { err }, { root: true })
    }
  },
  async fetchBookmarkedLots({ commit, dispatch }, hideLoading = false) {
    if (!hideLoading) {
      commit('SET_LOADING', true)
    }
    try {
      const res = await axiosWithToken.get('/api/user/favorite')
      commit('SET_BOOKMARKED_LOTS', res.data)
    } catch (err) {
      dispatch('common/setRequestError', { err }, { root: true })
    } finally {
      if (!hideLoading) {
        commit('SET_LOADING', false)
      }
    }
  }
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}
