import { axiosWithBaseUrl, axiosWithToken } from '@/api/axios'
import { isObject } from '@/utils'

const state = () => ({
  categories: [],
  countries: [],
  regions: [],
  sources: [],
  requestError: null
})

const getters = {
  categories: (state) => state.categories,
  countries: (state) => state.countries,
  regions: (state) => state.regions,
  sources: (state) => state.sources,
  requestError: (state) => state.requestError
}

const mutations = {
  SET_CATEGORIES(state, payload) {
    state.categories = payload
  },
  SET_COUNTRIES(state, payload) {
    state.countries = payload
  },
  SET_REGIONS(state, payload) {
    state.regions = payload
  },
  SET_SOURCES(state, payload) {
    state.sources = payload
  },
  SET_REQUEST_ERROR(state, payload) {
    state.requestError = payload
  },
  RESET_REQUEST_ERRORS(state) {
    state.requestError = null
  }
}

const actions = {
  async fetchCategories({ commit }) {
    try {
      const res = await axiosWithBaseUrl.get('/api/category')
      commit('SET_CATEGORIES', res.data)
    } catch (err) {
      console.error(err)
    }
  },
  async fetchCountries({ commit }) {
    try {
      const res = await axiosWithBaseUrl.get('/api/country')
      commit('SET_COUNTRIES', res.data)
    } catch (err) {
      console.error(err)
    }
  },
  async fetchRegions({ commit }) {
    try {
      const res = await axiosWithBaseUrl.get('/api/region')
      commit('SET_REGIONS', res.data)
    } catch (err) {
      console.error(err)
    }
  },
  async fetchSources({ commit }) {
    try {
      const res = await axiosWithToken.get('/api/source')
      commit('SET_SOURCES', res.data)
    } catch (err) {
      console.error(err)
    }
  },
  async fetchRequirements(
    { dispatch, rootGetters },
    { categories, countries, regions, sources } = {
      categories: false,
      countries: false,
      regions: false,
      sources: false
    }
  ) {
    if (categories) await dispatch('fetchCategories')
    if (countries) await dispatch('fetchCountries')
    if (regions) await dispatch('fetchRegions')

    if (rootGetters['auth/isUserLoggedIn']) {
      if (sources) await dispatch('fetchSources')
    }
  },
  setRequestError({ commit, dispatch }, { err, notify, message }) {
    if (!isObject(err)) return

    const res = err.response
    switch (res.status) {
      case 400:
        commit('SET_REQUEST_ERROR', res.data)
        if (typeof notify === 'function')
          if (message) {
            notify('error', 'error', res.data[message].join(', '))
          } else {
            notify('error', 'error', 'inputCorrectData')
          }
        break

      case 500:
        commit('SET_REQUEST_ERROR', 'Internal Server Error')
        if (typeof notify === 'function')
          notify('error', 'error', 'Internal Server Error')
        break

      case 401:
        commit('SET_REQUEST_ERROR', 'Unauthorized')
        dispatch('auth/signOut', null, { root: true })
        break

      case 403:
        commit('SET_REQUEST_ERROR', 'Forbidden')
        dispatch('auth/signOut', null, { root: true })
        break

      default:
        commit('SET_REQUEST_ERROR', null)
        break
    }
  }
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}
